<template>
    <div>
        <button @click="notify()">notification</button>
    </div>
</template>

<script>
export default {
    name: 'TestPage',
    data: () => {
        return {

        }
    },

    methods: {
        notify() {
            // Проверка поддержки браузером уведомлений
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
            }

            // Проверка разрешения на отправку уведомлений
            else if (Notification.permission === "granted") {
                // Если разрешено, то создаем уведомление
                var notification = new Notification("Hi there!");
            }

            // В противном случае, запрашиваем разрешение
            else {
                Notification.requestPermission(function (permission) {
                    // Если пользователь разрешил, то создаем уведомление 
                    if (permission === "granted") {
                        var notification = new Notification("Hi there!");
                    }
                });
            }
        }
    }
}
</script>

<style>

</style>